
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "documents",
  components: {},
  // props: {
  //   customerId: "",
  // },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Documents", ["Customer"]);
    });

    return {};
  },
  data() {
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    const customer = {} as any;

    const documents = [];
    const isProfileReady = false;

    return {
      store: useStore(),
      customer,
      cstmr_id,
      documents,
      isProfileReady
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
        this.customer = this.store.getters.getCustomerData as any;

        this.documents = this.customer.user_raw_profile.media

        this.isProfileReady = true;
      });
    },
  },
  async mounted() {
    this.init();
  },
});
